@import "../../styles/variables.scss";

.container-list-employees {
  //   height: 100%;
  display: grid;
  grid-template-columns: 10% 30% 25% 20% 15%;
  align-items: center;

  &--title {
    font-weight: 600;
    color: $colorText;
  }

  .employee {
    text-align: center;
  }

  &--checkbox {
    text-align: center;
    input.box {
      height: 25px;
      width: 25px;
      background: white;
      cursor: pointer;
    }
  }

  @media only screen and (max-width: 600px) {
    display: none;
  }
}
