@import "../../styles/variables.scss";

.container-sub-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 20px;

  p {
    font-size: 18px;
    font-weight: 600;
    display: flex;
    align-self: center;
  }

  button {
    color: white;
    border: none;
    font-size: 16px;
    padding: 15px 50px;
    border-radius: 10px;
    font-weight: 600;
    background: $colorPrincipal;
    outline: none;

    &:hover {
      cursor: pointer;
    }

    &:active {
      background: $colorPrincipalActive;
    }
  }

  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }
}
