@import "../../styles/variables.scss";

.container-dashboard {
  display: grid;
  grid-template-columns: 25% 75%;
  grid-template-areas: "subHeader subHeader" "cardCategories listEmployees";
  background-color: $colorBackground;

  padding-left: 30px;
  padding-right: 30px;
  margin-right: -30px;
  margin-left: -30px;
  padding-bottom: 30px;

  .sub-header {
    grid-area: subHeader;
  }

  .card-categories {
    grid-area: cardCategories;
  }

  .list-employees {
    grid-area: listEmployees;
  }

  @media only screen and (max-width: 600px) {
    grid-template-areas:
      "subHeader subHeader"
      "cardCategories cardCategories"
      "listEmployees listEmployees";
  }
}
