@import "../../styles/variables.scss";

.container-card-categories {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  margin-right: 20px;

  .category {
    padding: 20px;
    text-align: center;
    cursor: pointer;
    border-radius: 10px;
  }

  .category:hover {
    background-color: $colorBackground;
  }

  &--description {
    color: $colorTextSecondary;
    text-transform: uppercase;
  }

  .active {
    background-color: $colorBackground;
  }

  &--header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    margin: 20px 0;

    p {
      color: $colorTextSecondary;
    }
  }

  @media only screen and (max-width: 600px) {
    margin: 0 0 20px;
  }
}
