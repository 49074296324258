@import "../../styles/variables.scss";

.hero {
  display: grid;
  grid-template-columns: 1fr 1fr;
  color: $colorText;
  align-items: center;
  grid-template-areas: "textZone imageZone";

  @media only screen and (max-width: 992px) {
    .hero--title {
      font-size: 25px;
    }
  }

  @media only screen and (max-width: 600px) {
    .hero--title {
      font-size: 20px;
      text-align: center;
    }
    grid-template-areas:
      "textZone textZone"
      "imageZone imageZone";
  }

  .highlighted {
    color: $colorHighlighted;
  }

  &--title {
    font-size: 35px;
    grid-area: textZone;
  }

  &--button {
    margin-top: 15px;
    color: white;
    border: none;
    font-size: 20px;
    padding: 15px 50px;
    border-radius: 10px;
    font-weight: 600;
    background: $colorPrincipal;
  }

  &--textHighligh {
    color: $colorPrincipal;
    font-weight: 600;
  }

  &--image {
    text-align: center;
    grid-area: imageZone;
    &__size {
      width: 100%;
    }
  }
}
