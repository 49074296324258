@import "../../styles/variables.scss";

.container-login {
  display: grid;
  grid-template-columns: 30% 70%;

  grid-template-areas: "leftSide rightSide";

  @media only screen and (max-width: 768px) {
    grid-template-columns: 50% 50%;
    .right-side {
      img {
        width: 250px;
      }
    }
  }

  @media only screen and (max-width: 600px) {
    grid-template-areas: "leftSide leftSide";
    .right-side {
      display: none;
    }
  }
}

.left-side {
  grid-area: leftSide;
  //   background-color: lightblue;
  padding: 30px;

  &--title {
    color: $colorText;
    font-size: 25px;
    font-weight: 600;
    // padding-bottom: 10px;
  }
}

.login-group {
  display: flex;
  flex-direction: column;

  .button-login {
    margin-top: 15px;
    color: black;
    border: none;
    font-size: 20px;
    padding: 10px 50px;
    border: 4px solid $colorPrincipal;
    border-radius: 10px;
    font-weight: 600;
    background: white;
    outline: none;

    &--content {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
    }

    &:hover {
      cursor: pointer;
    }

    &:active {
      background: $colorPrincipalActive;
      border: 4px solid $colorPrincipalActive;
      color: white;
    }
  }
}

.right-side {
  grid-area: rightSide;
  height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 40px;

  img {
    width: 450px;
  }
}
