@import "../../styles/variables.scss";

.menu {
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  label {
    display: none;
    font-size: 20px;
    color: $colorText;
    cursor: pointer;
  }

  @media only screen and (max-width: 600px) {
    label {
      display: block;
    }
    .menu--items {
      display: none;
      position: absolute;
      margin: 26px 0 26px -30px;
      background-color: white;
      width: 100%;
      box-shadow: 0px 12px 19px -10px #cd7cf2;

      p {
        font-size: 20px;
        margin: 10px 0;
      }
      p:hover {
        border-bottom: 2px solid $colorInactiveBorder;
      }
    }

    #toggle:checked + .menu--items {
      display: block;
    }
  }

  #toggle {
    display: none;
  }

  &--items {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    justify-items: center;

    a {
      width: 100%;
      text-align: center;
      padding: 15px 0;
      border-bottom: 2px solid $colorInactiveBorder;
      text-decoration: none;
      color: black;
    }

    a:hover {
      border-bottom: 4px solid $colorPrincipal;
      color: $colorPrincipal;
    }
  }

  &--userName {
    margin: auto 0px auto auto;
    color: $colorText;
    font-size: 20px;
  }
}
