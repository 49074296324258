.Modal {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);

  display: flex;
  flex-direction: column;
  align-items: center;

  overflow-y: scroll;

  padding: 0 0 20%;
}

.Modal__container {
  position: relative;
  top: 20%;
  background-color: #ffffff;
  padding: 1rem;
  width: 400px;
  border-radius: 20px;
}

@media only screen and (max-width: 600px) {
  .Modal__container {
    position: relative;
    top: 20%;
    background-color: #ffffff;
    padding: 1rem;
    width: 80vw;
    border-radius: 20px;
  }
}

.Modal__close-button {
  border-top-right-radius: 20px;
  position: absolute;
  top: 0;
  right: 0;
  border: 0;
  background-color: #f5f5f5;
  padding: 0.5rem 1rem;
  outline: none;
  cursor: pointer;
}
